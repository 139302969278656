
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../config";
import {
  AgentInfo,
  MortgageClause,
  AppraisalCompany,
} from "../../models/agent/agent.model";
import CommonDeleteModal from "../../common-components/CommonDeleteModal.vue";
import { authHeader } from "../../services/auth";
import { LOAN_TYPE } from "../../constants/constants";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

@Component({
  components: {
    CommonDeleteModal,
    PerfectScrollbar,
  },
})
export default class Lender extends Vue {
  /* eslint-disable */
  public agentInfo: any = new AgentInfo();
  public mortgageClause: any = new MortgageClause();
  public appraisalcompany: any = new AppraisalCompany();
  public lenderList: any = [];
  public lenderId = null;
  public defaultSubmit = "Add";
  public isEdit = false;
  public isShow = false;
  public appraisalCompanyError = "";
  public totalLength = 0;
  public noChange = false;
  public currentLengthOnScroll = 0;
  public skip = 0;
  public fields = [
    "name",
    "website",
    "UserType",
    "Username",
    "Password",
    "address",
    "Edit",
    "createdOn",
    "Action",
  ];
  public clauseFields = [
    "name",
    "loanType",
    "applicableForTask",
    "clause",
    "address",
    "edit",
  ];
  public appraisalCompanyName = [];
  public loanType = LOAN_TYPE;
  public taskList = [];
  public lenderIndex = null;
  public clauseIndex = null;
  public viewButton = false;
  public clickCount = false;
  public showLoader = false;
  public flage = null;
  public search = "";
  public stateAndCounty = [];

  /*********************************************************************************
   *                     Save Lender Info                                          *
   *********************************************************************************/
  // public async saveLenderInfo() {
  //   try {
  //     this.agentInfo.type = 10
  //     const response = await Axios.post(
  //       `${BASE_API_URL}common/saveLenderInfo`,
  //       { ...this.agentInfo },
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //     if (response.data.status == 200) {
  //       this.$modal.hide("addLender");
  //       this.$snotify.success(response.data.msg);
  //       this.agentInfo = new AgentInfo();
  //       await this.getLenderList();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  public async saveLenderInfo() {
    try {
      let count = 0;
      const unique: any = [];
      if (this.agentInfo.appraisalcompany.length > 0) {
        this.agentInfo.appraisalcompany.forEach((e) => {
          if (!unique.includes(e.id)) unique.push(e.id);
          else count++;
        });
        if (count > 0) {
          this.appraisalCompanyError =
            "Please fill different appraisal companies";
          return;
        }
      }

      this.appraisalCompanyError = "";

      this.agentInfo.type = 10;
      // this.agentInfo.appraisalcompany = this.agentInfo.appraisalcompany.filter(a => a.id != null);
      this.agentInfo.mortgageClause = this.agentInfo.mortgageClause.filter(
        (m) => m.name != null
      );
      const response = await Axios.post(
        `${BASE_API_URL}common/saveLenderInfo`,
        this.agentInfo,
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$snotify.success(
          this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
        );
        this.agentInfo = new AgentInfo();
        this.$modal.hide("addLender");
        this.appraisalCompanyError = "";
        await this.getLenderList(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public getClicked(flage) {
    //console.log("bjhhdjfjsa", this.clickCount);
    this.flage = flage;
    if (this.clickCount == true) {
      this.clickCount = false;
    } else {
      this.clickCount = true;
    }
  }

  public openAddLenderModal() {
    this.agentInfo = new AgentInfo();
    this.$modal.show("addLender");
  }
  /*********************************************************************************
   *               Fetching Lender List                                            *
   *********************************************************************************/
  public async getLenderList(mountCall) {
    mountCall
      ? (this.$store.state.tmpcoLoader = true)
      : (this.showLoader = true);
    try {
      this.skip = mountCall ? 0 : this.skip + 15;
      const response = await Axios.get(BASE_API_URL + "common/getLenderList", {
        params: {
          search: this.search,
          options: {
            skip: this.skip,
            limit: 15,
          },
        },
        headers: authHeader(),
      });

      if (response.status === 201) {
        if (mountCall) {
          this.lenderList = response.data.promise ? response.data.promise : [];
        } else {
          const data = response.data.promise ? response.data.promise : [];
          this.lenderList = this.lenderList.concat(data);
        }
        this.totalLength = response.data.LenderdataLength;
        this.currentLengthOnScroll = this.lenderList
          ? this.lenderList.length
          : 0;
      }
      console.log(this.lenderList, "this.lenderList");
    } catch (error) {
      console.log(error);
    }
    mountCall
      ? (this.$store.state.tmpcoLoader = false)
      : (this.showLoader = false);
  }
  /***********************************************************************************
   *         Delete Lender Info                                                      *
   ***********************************************************************************/
  public async deleteLenderInfo(data) {
    try {
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${data.name} lender ?`,
        data: { id: data._id },
        apiPath: "agent/deleteLenderInfo",
      });
    } catch (error) {
      console.log(error);
    }
  }
  /**********************************************************************************
   *               Open modal for Add Mortgage Clause                               *
   **********************************************************************************/
  public openMortgageClauseModal(id) {
    this.lenderId = id;
    this.$modal.show("addMortgageClause");
    this.agentInfo = new AgentInfo();
  }
  /***********************************************************************************
   *          Saving Mortgage clause of specific lender                              *
   ***********************************************************************************/
  public async saveMortgageClauseOfLender() {
    try {
      const data = {
        lenderId: this.lenderId,
        mortgageClause: this.agentInfo.mortgageClause,
        defaultSubmit: this.defaultSubmit,
      };

      const response = await Axios.post(
        BASE_API_URL + "agent/saveMortgageClauseOfLender",
        { data: data },
        {
          headers: authHeader(),
        }
      );
      if (response.data.msg == "success") {
        this.$modal.hide("addMortgageClause");
        this.getLenderList(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  /**********************************************************************************
   *                Function for add More clause                                    *                                         *
   **********************************************************************************/
  public addMoreClause() {
    const data = new AgentInfo();
    this.agentInfo.mortgageClause.push({ ...data.mortgageClause[0] });
  }
  /*********************************************************************************
   *         Remove mortgage clause                                                *
   *********************************************************************************/
  public remove(index) {
    this.agentInfo.mortgageClause.splice(index, 1);
  }
  /*********************************************************************************
   *               Deleting mortgage clause of lender                              *
   *********************************************************************************/
  public async deleteMortgageClause(lenderId, indexId) {
    try {
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this mortgage clause ?`,
        data: { lenderId: lenderId, indexId: indexId },
        apiPath: "agent/deleteMortgageClause",
      });
    } catch (error) {
      console.log(error);
    }
  }
  /********************************************************************************
   *           Edit Mortgage clause                                               *
   ********************************************************************************/
  public editMortgageClause(data, lenderId) {
    this.isEdit = true;
    this.defaultSubmit = "Edit";
    this.lenderId = lenderId;
    this.agentInfo.mortgageClause[0] = JSON.parse(JSON.stringify(data));
    this.$modal.show("addMortgageClause");
  }
  /**
   *  Hiding modal on cancel
   */
  cancel() {
    this.$modal.hide("addLender");
    this.$modal.hide("addMortgageClause");
    this.lenderId = null;
  }

  openLenderEditModal(list) {
    console.log(list);
    this.agentInfo = JSON.parse(JSON.stringify(list));
    this.isEdit = true;
    this.$modal.show("addLender");
  }

  /************************************************/

  public addAppraisalCompany() {
    const data = new AgentInfo();
    this.agentInfo.appraisalcompany.push({ ...data.appraisalcompany[0] });
  }

  public removeAppraisalCompany(index) {
    this.agentInfo.appraisalcompany.splice(index, 1);
  }

  public async getAppraisalList() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "agent/getAppraisalNameList",
        {
          headers: authHeader(),
        }
      );
      this.appraisalCompanyName = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async getTaskList() {
    try {
      const response = await Axios.get(BASE_API_URL + "agent/getTaskList", {
        headers: authHeader(),
      });
      this.taskList = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public getData(applicableForTask) {
    this.taskList.forEach((e: any) => {
      if (e.id == applicableForTask) {
        applicableForTask = e.name;
      }
    });

    return applicableForTask;
  }

  public appraisalCompany(list) {
    this.agentInfo = JSON.parse(JSON.stringify(list));
    this.$modal.show("appraisalCompany");
  }

  public getCompanyName(data) {
    let name;
    this.appraisalCompanyName.forEach((e: any) => {
      if (e._id == data) name = e.name;
    });
    return name;
  }

  public goTo(data) {
    this.$router.push({ path: "/mortgage", query: data });
  }

  public async scrollHandle(evt) {
    let scroll: any = this.$refs.scrollbar;
    if (
      !this.showLoader &&
      scroll.ps.reach.y == "end" &&
      this.currentLengthOnScroll < this.totalLength
    ) {
      await this.getLenderList(false);
    }
  }

  public async getStatesAndCounty() {
    try {
      const response = await Axios.get(
        BASE_API_URL + "common/getStatesAndCounty"
      );
      this.stateAndCounty = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  /********************************************/

  async mounted() {
    await this.getLenderList(true);
    await this.getTaskList();
    await this.getAppraisalList();
    await this.getStatesAndCounty();
  }
}
